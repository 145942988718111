<template>
    <div class="mx-auto w-full max-w-sm">
        <div>
            <h2 class="mt-6 text-3xl leading-9 font-extrabold text-gray-900">
                {{ $translate('auth.login.title') }}
            </h2>
            <p class="mt-2 text-sm leading-5 text-gray-600 max-w">
                {{ $translate('auth.login.or') }}
                <router-link :to="{name: 'register'}" tag="a"
                             class="font-medium text-custom-600 hover:text-custom-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                    {{ $translate('auth.login.create') }}
                </router-link>
            </p>
        </div>
        <div class="mt-8">
            <div class="mt-6 space-y-6">
                <Alert :title="$translate('auth.login.expired')" type="error" v-if="expired" />
                <DataForm :form="form" v-model="values" @enter="login" />
                <div class="flex items-center justify-between">
                    <div class="text-sm leading-5">
                        <router-link :to="{name: 'forgot-password'}" tag="a"
                                     class="font-medium text-custom-600 hover:text-custom-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                            {{ $translate('auth.login.reset-password') }}
                        </router-link>
                    </div>
                </div>
                <span class="block w-full rounded-md shadow-sm">
                    <button type="submit"
                            class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-custom-600 hover:bg-custom-500 focus:outline-none focus:border-custom-700 focus:shadow-outline-custom active:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500"
                            @click="login">
                            <span class="pr-2" v-if="values.loading"><i class="fal fa-spinner-third fa-spin"></i></span>
                        {{ values.loading ? $translate('auth.login.signingin') : $translate('auth.login.signin') }}
                    </button>
                </span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {userStore} from '@/store/user';
    import {defineComponent, reactive} from 'vue'
    import {BaseForm, FormGrid} from '@/types/form';
    import {EmailValidationRule, FormValidation, RequiredValidationRule} from "@/types/validation";
    import {PasswordField, TextField} from "@/types/field";
    import router from '@/router';
    import {usePromise} from "@/components/usePromise";


    export default defineComponent({
        name: "Login",
        setup() {

            //  Form definition
            const form = reactive(new BaseForm(FormValidation.AfterSubmit, FormGrid.None));
            form.addField(
                new TextField('email', 'auth.login.email')
                    .setAutocomplete('username')
                    .addRule(new RequiredValidationRule())
                    .addRule(new EmailValidationRule())
            )
            form.addField(
                new PasswordField('password', 'auth.login.password')
                    .addRule(new RequiredValidationRule())
                    .setAutocomplete('current-password')
            );

            //  Login method
            const login = () => {
                if(values.loading) return;
                form.setError('').submit().then(() => {

                    //  Reset expired message
                    userStore.logout(false);

                    //  Try login user
                    values.loading = true;
                    userStore.login(values.email, values.password).then(() => {
                        values.loading = false;
                        router.push({name: 'dashboard'});
                    }).catch((error: string) => {
                        values.loading = false;
                        form.setError(error)
                    });
                });
            }

            //  Data object with user credentials
            const values = reactive({
                loading: false,
                email: '',
                password: '',
            });

            return {
                expired: userStore.getState().expired,
                form,
                login,
                values,
            }
        }
    })

</script>