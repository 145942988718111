
    import {userStore} from '@/store/user';
    import {defineComponent, reactive} from 'vue'
    import {BaseForm, FormGrid} from '@/types/form';
    import {EmailValidationRule, FormValidation, RequiredValidationRule} from "@/types/validation";
    import {PasswordField, TextField} from "@/types/field";
    import router from '@/router';
    import {usePromise} from "@/components/usePromise";


    export default defineComponent({
        name: "Login",
        setup() {

            //  Form definition
            const form = reactive(new BaseForm(FormValidation.AfterSubmit, FormGrid.None));
            form.addField(
                new TextField('email', 'auth.login.email')
                    .setAutocomplete('username')
                    .addRule(new RequiredValidationRule())
                    .addRule(new EmailValidationRule())
            )
            form.addField(
                new PasswordField('password', 'auth.login.password')
                    .addRule(new RequiredValidationRule())
                    .setAutocomplete('current-password')
            );

            //  Login method
            const login = () => {
                if(values.loading) return;
                form.setError('').submit().then(() => {

                    //  Reset expired message
                    userStore.logout(false);

                    //  Try login user
                    values.loading = true;
                    userStore.login(values.email, values.password).then(() => {
                        values.loading = false;
                        router.push({name: 'dashboard'});
                    }).catch((error: string) => {
                        values.loading = false;
                        form.setError(error)
                    });
                });
            }

            //  Data object with user credentials
            const values = reactive({
                loading: false,
                email: '',
                password: '',
            });

            return {
                expired: userStore.getState().expired,
                form,
                login,
                values,
            }
        }
    })

